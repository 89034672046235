<template>
    <div>
        <h1>Cell state analysis</h1>
        <h2>NMF signatures</h2>
        <div ref="network" style="width: 100%; height: 600px;"></div>
        <h2>Signature network/UMAP</h2>
        <div class="dropdown-container">
            <select v-model="selectedSignatureAttribute" @change="fetchSignatureScatter" class="dropdown-select">
                <option disabled value="">Select Attribute</option>
                <option v-for="signatureAttribute in signatureAttributes" :key="signatureAttribute">
                    {{ signatureAttribute }}</option>
            </select>
        </div>
        <div class="charts-container" position="relative">
            <div class="chart-container" style="width: 100%;">
                <div ref="signatureScatter" class="chart square-chart" style="width: 50%; height: 600px; margin-top: 20px; margin: auto;"></div>
                <div ref="signatureScatterLegendContainer" class="legend-container" style="width: 50%; right: 25%;"></div>
            </div>
        </div>
        <h2>Clinical applications</h2>

        <!-- Boxplot Container -->
        <div class="boxplot-container">
            <!-- Long Boxplot with Dropdown -->
            <div class="boxplot" style="position: relative;">
                <div class="dropdown-container boxplot-dropdown">
                    <select v-model="selectedLongDataset" @change="fetchLongFiles" v-if="longDatasets.length"
                        class="dropdown-select">
                        <option disabled value="">Select</option>
                        <option v-for="longDataset in longDatasets" :key="longDataset">{{ longDataset }}</option>
                    </select>
                    <select v-model="selectedLongFile" @change="fetchLongExpressions" v-if="longFiles.length"
                        class="dropdown-select">
                        <option disabled value="">Select</option>
                        <option v-for="longFile in longFiles" :key="longFile">{{ longFile }}</option>
                    </select>
                </div>
                <div ref="longBoxplot" class="chart" style="width: 100%; height: 600px;"></div>
            </div>

            <!-- Cross Boxplot with Dropdown -->
            <div class="boxplot" style="position: relative;">
                <div class="dropdown-container boxplot-dropdown">
                    <select v-model="selectedCrossDataset" @change="fetchCrossFiles" v-if="crossDatasets.length"
                        class="dropdown-select">
                        <option disabled value="">Select</option>
                        <option v-for="crossDataset in crossDatasets" :key="crossDataset">{{ crossDataset }}</option>
                    </select>
                    <div v-if="crossFiles.length > 0">
                        <select v-model="selectedCrossFile" @change="fetchCrossExpressions" class="dropdown-select">
                            <option disabled value="">Select</option>
                            <option v-for="crossFile in crossFiles" :key="crossFile">{{ crossFile }}</option>
                        </select>
                    </div>
                    <span v-else-if="selectedCrossDataset && crossFiles.length === 0" class="dropdown-warning">
                        This program is empty, please select another program.
                    </span>
                </div>
                <div ref="crossBoxplot" class="chart" style="width: 100%; height: 600px;"></div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
import * as echarts from 'echarts';
import pako from 'pako';
import seedrandom from 'seedrandom';
import 'vue3-select/dist/vue3-select.css';

export default {
    data() {
        return {
            networkData: null,
            signatureAttributes: ['presig_cell_type', 'presig_dataset_id', 'presig_lesion_orig', 'presig_lesion_revised', 'presig_cancer_source'],
            selectedSignatureAttribute: "",
            signatureData: [],
            longDatasets: [],
            selectedLongDataset: null,
            longFiles: [],
            selectedLongFile: null,
            crossDatasets: [],
            selectedCrossDataset: null,
            crossFiles: [],
            selectedCrossFile: null,
        };
    },
    mounted() {
        this.fetchNetwork();
        this.selectedSignatureAttribute = this.signatureAttributes[0];
        this.fetchSignatureScatter();
        this.fetchClinicalDatasets();
        this.renderSignatureScatter(this.$refs.signatureScatter);
        this.renderBulkBoxplot({}, echarts.init(this.$refs.longBoxplot), 'Box Plot of Longitudinal Bulk Expressions by Category');
        this.renderBulkBoxplot({}, echarts.init(this.$refs.crossBoxplot), 'Box Plot of Cross-sectional Bulk Expressions by Category');
        window.addEventListener('resize', this.adjustScatterPlotSize);
        this.$nextTick(this.adjustScatterPlotSize);
    },
    beforeMount() {
        window.removeEventListener('resize', this.adjustScatterPlotSize);
    },
    methods: {
        async fetchNetwork() {
            try {
                const response = await axios.get(`${process.env.VUE_APP_API_BASE_URL}/networks`);
                this.networkData = response.data.networks;
                this.renderNetwork();
            } catch (error) {
                console.error('Error fetching network data:', error);
            }
        },
        renderNetwork() {
            if (!this.networkData) return;

            const nodes = [];
            const links = [];
            const categories = [];
            const nodeToCategory = {};


            const assignCategory = (node, category) => {
                if (nodeToCategory[node] !== undefined) return;
                nodeToCategory[node] = category;
                if (Array.isArray(this.networkData[node])) {
                    this.networkData[node].forEach(target => assignCategory(target, category));
                }
            };
            let categoryCount = 0;
            Object.keys(this.networkData).forEach(node => {
                if (nodeToCategory[node] === undefined) {
                    assignCategory(node, categoryCount);
                    categories.push({ name: `Component ${categoryCount}` });
                    categoryCount++;
                }
            });
            // Construct nodes and links from adjacency list
            Object.keys(this.networkData).forEach(node => {
                nodes.push({
                    id: node,
                    name: node,
                    category: nodeToCategory[node],
                });
                if (Array.isArray(this.networkData[node])) {
                    this.networkData[node].forEach(target => {
                        links.push({
                            source: node,
                            target: target,
                        });
                    });
                }
            });

            const chart = echarts.init(this.$refs.network);
            const option = {
                tooltip: {
                    formatter: function (params) {
                        if (params.dataType === 'node') {
                            return `Node: ${params.data.name}`;
                        } else if (params.dataType === 'edge') {
                            return `Edge: ${params.data.source} - ${params.data.target}`;
                        }
                    }
                },
                lengend: {
                    data: categories.map(category => category.name),
                    bottom: 'bottom',
                    type: 'scroll',
                },
                series: [
                    {
                        name: 'Network',
                        type: 'graph',
                        layout: 'force',
                        data: nodes,
                        links: links,
                        categories: categories,
                        roam: true,
                        label: {
                            show: true,
                            position: 'right',
                            fontWeight: 'bold',
                        },
                        force: {
                            repulsion: 70,
                            edgeLength: [50, 100],
                        },
                    }
                ],
            };

            chart.setOption(option);
        },
        async fetchSignatureScatter() {
            try {
                const response = await axios.get(`${process.env.VUE_APP_API_BASE_URL}/signaturescatter`, {
                    params: {
                        signatureAttribute: this.selectedSignatureAttribute
                    },
                    responseType: 'arraybuffer'
                });

                const decompressedData = pako.ungzip(new Uint8Array(response.data), { to: 'string' });
                const rows = decompressedData.trim().split('\n');
                const headers = rows[0].split('\t');
                this.signatureData = rows.slice(1).map(row => {
                    const values = row.split('\t');
                    return headers.reduce((obj, header, index) => {
                        obj[header] = values[index];
                        return obj;
                    }, {});
                });
                this.renderSignatureScatter(this.$refs.signatureScatter);
            } catch (error) {
                console.error('Error fetching scatter data:', error);
            }
        },
        renderSignatureScatter(chartObj) {
            // if (!this.selectedSignatureAttribute || this.signatureData.length === 0) return;
            const rng = seedrandom('fixed-seed');
            let data = this.signatureData.slice();
            if (data.length > 5000) {
                for (let i = data.length - 1; i > 0; i--) {
                    const j = Math.floor(rng() * (i + 1));
                    [data[i], data[j]] = [data[j], data[i]];
                }
                data = data.slice(0, 5000);
            }
            const chart = echarts.init(chartObj);
            const categories = [...new Set(data.map(item => item['colortype']))];
            const colors = Array.from({ length: categories.length }, (_, i) =>
                echarts.color.modifyHSL('#ff0000', i * (360 / categories.length), 0.7, 0.7)
            );
            const colorMap = categories.reduce((acc, category, index) => {
                acc[category] = colors[index];
                return acc;
            }, {});
            const containerWidth = chartObj.clientWidth;
            const containerHeight = chartObj.clientHeight;
            const size = Math.min(containerWidth, containerHeight) - 100;
            const left = (containerWidth - size) / 2;

            const option = {
                title: {
                    text: `Signature UMAP across ${this.selectedSignatureAttribute}`,
                    left: 'center'
                },
                tooltip: {
                    trigger: 'item',
                    formatter: function (params) {
                        return `${params.marker} ${params.data.name}: [${params.data.value[0].toFixed(4)}, ${params.data.value[1].toFixed(4)}]`;
                    }
                },
                grid: {
                    left: `${left}px`,
                    right: `${left}px`,
                    bottom: '10%',
                    containLabel: true,
                    width: size,
                    height: size,
                },
                xAxis: {
                    type: 'value',
                    scale: true,
                    axisLine: { show: false },
                    axisTick: { show: false },
                    splitLine: { show: true }
                },
                yAxis: {
                    type: 'value',
                    scale: true,
                    axisLine: { show: false },
                    axisTick: { show: false },
                    splitLine: { show: true }
                },
                series: [
                    {
                        name: 'Scatter',
                        type: 'scatter',
                        symbolSize: 2,
                        data: data.map(item => ({
                            value: [
                                parseFloat(item['TSNE_1']),
                                parseFloat(item['TSNE_2'])
                            ],
                            name: item['colortype'],
                            itemStyle: {
                                color: colorMap[item['colortype']]
                            }
                        }))
                    }
                ]
            };

            chart.setOption(option);
            this.createLegend(colorMap);
        },
        createLegend(colorMap) {
            const legendContainer = this.$refs.signatureScatterLegendContainer;
            legendContainer.innerHTML = '';

            Object.keys(colorMap).forEach(category => {
                const legendItem = document.createElement('span');
                legendItem.style.display = 'flex';
                legendItem.style.alignItems = 'center';
                legendItem.style.marginBottom = '5px';
                legendItem.style.marginRight = '15px';

                const colorBox = document.createElement('span');
                colorBox.style.width = '25px';
                colorBox.style.height = '14.4px';
                colorBox.style.backgroundColor = colorMap[category];
                colorBox.style.marginRight = '10px';
                colorBox.style.borderRadius = '3px';

                const labelText = document.createElement('span');
                labelText.textContent = category;
                labelText.style.fontSize = '12px';
                labelText.style.fontFamily = 'Helvetica, sans-serif';
                labelText.style.fontWeight = 'semibold';

                legendItem.appendChild(colorBox);
                legendItem.appendChild(labelText);
                legendContainer.appendChild(legendItem);
            });
        },
        async fetchClinicalDatasets() {
            try {
                const longResponse = await axios.get(`${process.env.VUE_APP_API_BASE_URL}/bulkdatasets`, {
                    params: {
                        type: 'long'
                    }
                });
                this.longDatasets = longResponse.data.bulkDatasets;
                this.selectedLongDataset = this.longDatasets[0];
                this.fetchLongFiles();
                const crossResponse = await axios.get(`${process.env.VUE_APP_API_BASE_URL}/bulkdatasets`, {
                    params: {
                        type: 'cross'
                    }
                });
                this.crossDatasets = crossResponse.data.bulkDatasets;
                this.selectedCrossDataset = this.crossDatasets[2];
                this.fetchCrossFiles();
            } catch (error) {
                console.error('Error fetching cancer types:', error);
            }
        },
        async fetchLongFiles() {
            try {
                const response = await axios.get(`${process.env.VUE_APP_API_BASE_URL}/bulkfiles`, {
                    params: {
                        type: 'long',
                        bulkDataset: this.selectedLongDataset
                    }
                });
                this.selectedLongFile = '';
                this.longFiles = response.data.bulkFiles;
                this.selectedLongFile = this.longFiles[0];
                this.fetchLongExpressions();
            } catch (error) {
                console.error('Error fetching metadata:', error);
            }
        },
        async fetchLongExpressions() {
            try {
                const bulkResponse = await axios.get(`${process.env.VUE_APP_API_BASE_URL}/bulkexpressions`, {
                    params: {
                        type: 'long',
                        bulkDataset: this.selectedLongDataset,
                        bulkFile: this.selectedLongFile
                    },
                    responseType: 'arraybuffer'
                });

                const decompressedData = pako.ungzip(new Uint8Array(bulkResponse.data), { to: 'string' });
                const rows = decompressedData.trim().split('\n');
                const dataByCategory = {};

                rows.forEach(row => {
                    const [value, category] = row.split('\t');
                    if (!dataByCategory[category]) {
                        dataByCategory[category] = [];
                    }
                    dataByCategory[category].push(parseFloat(value));
                });
                const chart = echarts.init(this.$refs.longBoxplot);
                const title = 'Box Plot of Longitudinal Bulk Expressions by Category';
                this.renderBulkBoxplot(dataByCategory, chart, title);
            } catch (error) {
                console.error('Error fetching programs:', error);
            }
        },
        async fetchCrossFiles() {
            try {
                const response = await axios.get(`${process.env.VUE_APP_API_BASE_URL}/bulkfiles`, {
                    params: {
                        type: 'cross',
                        bulkDataset: this.selectedCrossDataset
                    }
                });
                this.selectedCrossFile = '';
                this.crossFiles = response.data.bulkFiles;
                this.selectedCrossFile = this.crossFiles[0];
                this.fetchCrossExpressions();
            } catch (error) {
                console.error('Error fetching metadata:', error);
            }
        },
        async fetchCrossExpressions() {
            try {
                const bulkResponse = await axios.get(`${process.env.VUE_APP_API_BASE_URL}/bulkexpressions`, {
                    params: {
                        type: 'cross',
                        bulkDataset: this.selectedCrossDataset,
                        bulkFile: this.selectedCrossFile
                    },
                    responseType: 'arraybuffer'
                });

                const decompressedData = pako.ungzip(new Uint8Array(bulkResponse.data), { to: 'string' });
                const rows = decompressedData.trim().split('\n');
                const dataByCategory = {};

                rows.forEach(row => {
                    const [value, category] = row.split('\t');
                    if (!dataByCategory[category]) {
                        dataByCategory[category] = [];
                    }
                    dataByCategory[category].push(parseFloat(value));
                });
                const chart = echarts.init(this.$refs.crossBoxplot);
                const title = 'Box Plot of Cross-sectional Bulk Expressions by Category';
                this.renderBulkBoxplot(dataByCategory, chart, title);
            } catch (error) {
                console.error('Error fetching programs:', error);
            }
        },
        renderBulkBoxplot(dataByCategory, chart, title) {
            const categories = Object.keys(dataByCategory);

            const boxData = [];
            const tooltipData = [];
            const outliersData = [];

            categories.forEach((category, index) => {
                const values = dataByCategory[category];
                values.sort((a, b) => a - b);
                const rawMin = values[0];
                const rawMax = values[values.length - 1];
                const q1 = values[Math.floor(values.length / 4)];
                const median = values[Math.floor(values.length / 2)];
                const q3 = values[Math.floor(values.length * 3 / 4)];
                const iqr = q3 - q1;
                const lowerFence = q1 - 1.5 * iqr;
                const upperFence = q3 + 1.5 * iqr;

                const actualMin = values.find(v => v >= lowerFence) || rawMin;
                const actualMax = values.reverse().find(v => v <= upperFence) || rawMax;

                // Data for box plot
                boxData.push([actualMin, q1, median, q3, actualMax]);

                // Extended data for tooltip
                tooltipData.push([
                    index,        // category index
                    actualMin,    // actual minimum
                    q1,           // first quartile
                    median,       // median
                    q3,           // third quartile
                    actualMax,    // actual maximum
                    rawMin,       // raw minimum
                    rawMax        // raw maximum
                ]);

                // Outliers
                const outlierPoints = values.filter(value => value < lowerFence || value > upperFence).map(value => {
                    return [index, value]; // Use category index as the x-coordinate
                });
                outliersData.push(...outlierPoints);
            });

            const option = {
                title: {
                    text: title,
                    left: 'center'
                },
                tooltip: {
                    trigger: 'item',
                    formatter: function (params) {
                        if (params.componentType === 'series' && params.seriesType === 'scatter') {
                            return `Category: ${categories[params.data[0]]}<br/>Outlier: ${params.data[1]}`;
                        } else if (params.componentType === 'series' && params.seriesType === 'boxplot') {
                            const data = tooltipData[params.dataIndex];
                            return `
                        Category: ${categories[data[0]]}<br/>
                        Min (Actual): ${data[1]}<br/>
                        Q1: ${data[2]}<br/>
                        Median: ${data[3]}<br/>
                        Q3: ${data[4]}<br/>
                        Max (Actual): ${data[5]}<br/>
                        Min (Raw): ${data[6]}<br/>
                        Max (Raw): ${data[7]}
                    `;
                        }
                    }
                },
                xAxis: {
                    type: 'category',
                    data: categories
                },
                yAxis: {
                    type: 'value'
                },
                series: [
                    {
                        name: 'Bulk Expression',
                        type: 'boxplot',
                        data: boxData,
                        itemStyle: {
                            borderWidth: 2
                        },
                        boxWidth: ['15%', '30%'],
                        emphasis: {
                            itemStyle: {
                                borderColor: '#c23531',
                                borderWidth: 3,
                                shadowBlur: 5,
                                shadowColor: 'rgba(0, 0, 0, 0.5)'
                            }
                        },
                    },
                    {
                        name: 'Outliers',
                        type: 'scatter',
                        data: outliersData
                    }
                ]
            };
            chart.setOption(option);
        },
        adjustScatterPlotSize() {
            this.$nextTick(() => {
                const scatterPlot = this.$refs.signatureScatter;
                if (scatterPlot) {
                    const width = scatterPlot.clientWidth;
                    const height = scatterPlot.clientHeight;
                    const size = Math.min(width, height);
                    const chart = echarts.init(scatterPlot);
                    chart.resize();
                    chart.setOption({
                        grid: {
                            width: size,
                            height: size
                        }
                    });
                }
            });
        },
    }
};
</script>

<style>
.dropdown-container {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-left: 5%;
    z-index: 1; /* Ensure the dropdown stays on top of the chart */
}

.dropdown-select {
    font-size: 16px;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
}

.boxplot-container {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
}

.boxplot {
    width: 48%;
    /* Each boxplot will take approximately half of the container width */
    position: relative;
}

.chart {
    margin-top: 50px;
    width: 100%;
    height: 600px;
}

.boxplot-dropdown {
    position: absolute;
    top: 10px;
    left: 10px;
    /* Adjust the dropdown position relative to the boxplot container */
}

.dropdown-warning {
    color: red;
}
</style>