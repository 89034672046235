<template>
    <div>
        <h1>Cell abundance analysis</h1>
        <h2>Cell types UMAP</h2>
        <div class="dropdown-container">
            <select v-model="selectedSCDataset" @change="handleSCDatasetChange" v-if="scDatasets.length"
                class="dropdown-select">
                <option disabled value="">Select</option>
                <option v-for="scDataset in scDatasets" :key="scDataset">{{ scDataset }}</option>
            </select>
            <select v-model="selectedAttribute" @change="fetchSCScatter" class="dropdown-select">
                <option disabled value="">Select Attribute</option>
                <option v-for="scAttribute in scAttributes" :key="scAttribute">{{ scAttribute }}</option>
            </select>
        </div>
        <div class="charts-container">
            <div class="chart-container">
                <div ref="barChart" class="chart"></div>
            </div>
            <div class="chart-container">
                <div ref="scatterPlot" class="chart"></div>
                <div ref="legendContainer" class="legend-container"></div>
            </div>
        </div>
        <h2>Cell abundance</h2>
        <div class="table-container">
            <table v-if="scAbundanceTable.headers.length">
                <thead>
                    <tr>
                        <th v-for="header in scAbundanceTable.headers" :key="header">{{ header }}</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(row, index) in scAbundanceTable.rows" :key="index">
                        <td v-for="(cell, cellIndex) in row" :key="cellIndex">{{ cell }}</td>
                    </tr>
                </tbody>
            </table>
        </div>
        <h2>Marker Profiles</h2>
        <div class="table-container">
            <table v-if="scMarkerTable.headers.length">
                <thead>
                    <tr>
                        <th v-for="header in scMarkerTable.headers" :key="header">{{ header }}</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(row, index) in paginatedRows" :key="index">
                        <td v-for="(cell, cellIndex) in row" :key="cellIndex">{{ cell }}</td>
                    </tr>
                </tbody>
            </table>

            <div class="pagination" v-if="scMarkerTable.headers.length">
                <button @click="goToPage(1)" :disabled="currentPage === 1">First</button>
                <button @click="goToPage(currentPage - 1)" :disabled="currentPage === 1">Previous</button>

                <button v-for="page in visiblePages" :key="page" @click="goToPage(page)"
                    :class="{ active: currentPage === page }">
                    {{ page }}
                </button>

                <button @click="goToPage(currentPage + 1)" :disabled="currentPage === totalPages">Next</button>
                <button @click="goToPage(totalPages)"
                    :disabled="currentPage === totalPages">Last({{ totalPages }})</button>
                <input type="number" v-model.number="inputPage" @keyup.enter="goToInputPage" min="1"
                    :max="totalPages" />
                <button @click="goToInputPage">Go</button>
            </div>
        </div>
        <h2>Similarity profiles across organs</h2>
    </div>
</template>

<script>
import axios from 'axios';
import * as echarts from 'echarts';
import pako from 'pako';
import seedrandom from 'seedrandom';

export default {
    data() {
        return {
            scatterChart: null,
            barChart: null,
            scDatasets: [],
            selectedSCDataset: '',
            lesionStats: {},
            scAbundanceTable: {
                headers: [],
                rows: []
            },
            scatterData: [],
            selectedAttribute: '',
            scAttributes: ['presig_cell_type', 'presig_dataset_id', 'presig_lesion_orig', 'presig_lesion_revised', 'presig_cancer_source'],
            scMarkerTable: {
                headers: [],
                rows: []
            },
            currentPage: 1,
            rowsPerPage: 10,
            nearbyPagesCount: 2,
        };
    },
    mounted() {
        this.fetchSCDatasets();
        window.addEventListener('resize', this.adjustScatterPlotSize);
        this.$nextTick(this.adjustScatterPlotSize);
    },
    beforeUnmount() {
        window.removeEventListener('resize', this.adjustScatterPlotSize);
        this.disposeCharts();
    },
    computed: {
        totalPages() {
            return Math.ceil(this.scMarkerTable.rows.length / this.rowsPerPage);
        },
        paginatedRows() {
            const start = (this.currentPage - 1) * this.rowsPerPage;
            const end = start + this.rowsPerPage;
            return this.scMarkerTable.rows.slice(start, end);
        },
        visiblePages() {
            let start = Math.max(1, this.currentPage - this.nearbyPagesCount);
            let end = Math.min(this.totalPages, this.currentPage + this.nearbyPagesCount);

            const pages = [];
            for (let i = start; i <= end; i++) {
                pages.push(i);
            }

            return pages;
        }
    },
    methods: {
        async fetchSCDatasets() {
            try {
                this.selectedSCDataset = 'BRCA'; // prefetch BRCA
                this.handleSCDatasetChange();
                const response = await axios.get(`${process.env.VUE_APP_API_BASE_URL}/scdatasets`);
                this.scDatasets = response.data.scDatasets;
            } catch (error) {
                console.error('Error fetching cancer types:', error);
            }
        },
        async fetchLesions() {
            try {
                const response = await axios.get(`${process.env.VUE_APP_API_BASE_URL}/lesions`, {
                    params: {
                        tableName: `${this.selectedSCDataset.toLowerCase()}s`
                    }
                });
                this.lesionStats = response.data.lesionStats;
                this.renderBarChart();
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        },
        async fetchSCScatter() {
            try {
                const response = await axios.get(`${process.env.VUE_APP_API_BASE_URL}/scscatter`, {
                    params: {
                        tableName: `${this.selectedSCDataset.toLowerCase()}s`,
                        attribute: this.selectedAttribute
                    },
                    responseType: 'arraybuffer'
                });

                const decompressedData = pako.ungzip(new Uint8Array(response.data), { to: 'string' });
                const rows = decompressedData.trim().split('\n');
                const headers = rows[0].split('\t');
                this.scatterData = rows.slice(1).map(row => {
                    const values = row.split('\t');
                    return headers.reduce((obj, header, index) => {
                        obj[header] = values[index];
                        return obj;
                    }, {});
                });
                this.renderSCScatter(this.$refs.scatterPlot);
            } catch (error) {
                console.error('Error fetching scatter data:', error);
            }
        },
        async fetchSCAbundanceData() {
            try {
                const response = await axios.get(`${process.env.VUE_APP_API_BASE_URL}/scabundancedata`, {
                    params: {
                        scAbundanceDataset: this.selectedSCDataset
                    },
                    responseType: 'arraybuffer'
                });

                const decompressedData = pako.ungzip(new Uint8Array(response.data), { to: 'string' });
                const lines = decompressedData.trim().split('\n');
                let headers = lines[0].split('\t');
                const rows = lines.slice(1).map(line => {
                    const values = line.split('\t');
                    values[1] = Number(values[1]).toFixed(3);
                    values[2] = Number(values[2]).toFixed(3);
                    return values;
                });
                if (headers.length + 1 === rows[0].length) {
                    headers = ['', ...headers];
                }

                this.scAbundanceTable = { headers, rows };
            } catch (error) {
                console.error('Error fetching scatter data:', error);
            }
        },
        renderBarChart() {
            if (!this.$refs.barChart) return; // Ensure the DOM element exists

            const seriesData = this.lesionStats;

            let xAxisData = Object.keys(seriesData);
            let legendDataSet = new Set();

            xAxisData.forEach(diseaseName => {
                Object.keys(seriesData[diseaseName]).forEach(cellType => {
                    legendDataSet.add(cellType);
                });
            });

            let legendData = Array.from(legendDataSet);

            let series = legendData.map(cellTypeItem => {
                return {
                    name: cellTypeItem,
                    type: 'bar',
                    stack: 'cellType',
                    data: xAxisData.map(diseaseName => {
                        let total = Object.values(seriesData[diseaseName]).reduce((sum, val) => sum + val, 0);
                        let composition = seriesData[diseaseName][cellTypeItem] || 0;
                        return (composition / total) * 100;
                    })
                };
            });

            const option = {
                title: {
                    text: 'Cellular distribution across lesions',
                    left: 'center'
                },
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                        type: 'shadow'
                    },
                    formatter: params => {
                        let tooltipText = params[0].name + '<br/>';
                        params.forEach(item => {
                            tooltipText += item.marker + ' ' + item.seriesName + ': ' + item.value.toFixed(4) + '%<br/>';
                        });
                        return tooltipText;
                    }
                },
                legend: {
                    data: legendData,
                    bottom: 'bottom'
                },
                grid: {
                    containLabel: true
                },
                xAxis: [
                    {
                        type: 'category',
                        data: xAxisData,
                        axisLabel: {
                            interval: 0
                        }
                    }
                ],
                yAxis: [
                    {
                        type: 'value',
                        axisLabel: {
                            formatter: '{value} %'
                        }
                    }
                ],
                series: series
            };

            this.$nextTick(() => {
                if (this.barChart) {
                    this.barChart.dispose();
                }
                if (this.$refs.barChart) {
                    this.barChart = echarts.init(this.$refs.barChart);
                    this.barChart.setOption(option);
                }
            });
        },
        renderSCScatter(chartObj) {
            // if (!this.selectedAttribute || this.scatterData.length === 0) return;
            if (!chartObj) return;
            const rng = seedrandom('fixed-seed');
            let data = this.scatterData.slice();
            if (data.length > 5000) {
                for (let i = data.length - 1; i > 0; i--) {
                    const j = Math.floor(rng() * (i + 1));
                    [data[i], data[j]] = [data[j], data[i]];
                }
                data = data.slice(0, 5000);
            }
            if (!chartObj) return;
            this.scatterChart = echarts.init(chartObj);
            const categories = [...new Set(data.map(item => item['colortype']))];
            const colors = Array.from({ length: categories.length }, (_, i) =>
                echarts.color.modifyHSL('#ff0000', i * (360 / categories.length), 0.7, 0.7)
            );
            const colorMap = categories.reduce((acc, category, index) => {
                acc[category] = colors[index];
                return acc;
            }, {});
            const containerWidth = chartObj.clientWidth;
            const containerHeight = chartObj.clientHeight;
            const size = Math.min(containerWidth, containerHeight) - 75;
            const left = (containerWidth - size) / 2;

            const option = {
                title: {
                    text: `Cellular UMAP across ${this.selectedAttribute}`,
                    left: 'center'
                },
                tooltip: {
                    trigger: 'item',
                    formatter: function (params) {
                        return `${params.marker} ${params.data.name}: [${params.data.value[0].toFixed(4)}, ${params.data.value[1].toFixed(4)}]`;
                    }
                },
                grid: {
                    left: `${left}px`,
                    right: `${left}px`,
                    bottom: '10%',
                    containLabel: true,
                    width: size,
                    height: size,
                },
                xAxis: {
                    type: 'value',
                    scale: true,
                    axisLine: { show: false },
                    axisTick: { show: false },
                    splitLine: { show: true }
                },
                yAxis: {
                    type: 'value',
                    scale: true,
                    axisLine: { show: false },
                    axisTick: { show: false },
                    splitLine: { show: true }
                },
                series: [
                    {
                        name: 'Scatter',
                        type: 'scatter',
                        symbolSize: 2,
                        data: data.map(item => ({
                            value: [
                                parseFloat(item['umap_1']),
                                parseFloat(item['umap_2'])
                            ],
                            name: item['colortype'],
                            itemStyle: {
                                color: colorMap[item['colortype']]
                            }
                        }))
                    }
                ],
                graphic: {
                    type: 'text',
                    left: 'center',
                    bottom: -10,
                    style: {
                        text: `Cellular UMAP across ${this.selectedAttribute}`,
                    }
                }
            };

            this.scatterChart.setOption(option);
            this.createLegend(colorMap);
        },

        createLegend(colorMap) {
            const legendContainer = this.$refs.legendContainer;
            legendContainer.innerHTML = '';

            Object.keys(colorMap).forEach(category => {
                const legendItem = document.createElement('span');
                legendItem.style.display = 'flex';
                legendItem.style.alignItems = 'center';
                legendItem.style.marginBottom = '5px';
                legendItem.style.marginRight = '15px';

                const colorBox = document.createElement('span');
                colorBox.style.width = '25px';
                colorBox.style.height = '14.4px';
                colorBox.style.backgroundColor = colorMap[category];
                colorBox.style.marginRight = '10px';
                colorBox.style.borderRadius = '3px';

                const labelText = document.createElement('span');
                labelText.textContent = category;
                labelText.style.fontSize = '12px';
                labelText.style.fontFamily = 'Helvetica, sans-serif';
                labelText.style.fontWeight = 'semibold';

                legendItem.appendChild(colorBox);
                legendItem.appendChild(labelText);
                legendContainer.appendChild(legendItem);
            });
        },
        async fetchSCMarkerData() {
            try {
                const response = await axios.get(`${process.env.VUE_APP_API_BASE_URL}/scmarkerdata`, {
                    params: {
                        scMarkerDataset: this.selectedSCDataset
                    },
                    responseType: 'arraybuffer'
                });

                const decompressedData = pako.ungzip(new Uint8Array(response.data), { to: 'string' });
                const lines = decompressedData.trim().split('\n');
                let headers = lines[0].split('\t');
                const rows = lines.slice(1).map(line => {
                    const values = line.split('\t');
                    values[0] = Number(values[0]).toFixed(3);
                    values[1] = Number(values[1]).toFixed(3);
                    values[4] = Number(values[4]).toFixed(3);
                    return values;
                });

                this.scMarkerTable = { headers, rows };
                console.log(this.scMarkerTable);
            } catch (error) {
                console.error('Error fetching scatter data:', error);
            }
        },
        async handleSCDatasetChange() {
            await this.fetchLesions();
            await this.fetchSCAbundanceData();
            await this.fetchSCMarkerData();
            this.selectedAttribute = this.scAttributes[0];
            this.fetchSCScatter();
        },
        goToPage(page) {
            if (page >= 1 && page <= this.totalPages) {
                this.currentPage = page;
            }
        },
        goToInputPage() {
            if (this.inputPage >= 1 && this.inputPage <= this.totalPages) {
                this.goToPage(this.inputPage);
            }
        },
        adjustScatterPlotSize() {
            this.$nextTick(() => {
                const scatterPlot = this.$refs.scatterPlot;
                if (scatterPlot && this.scatterChart) {
                    const width = scatterPlot.clientWidth;
                    const height = scatterPlot.clientHeight;
                    const size = Math.min(width, height);
                    this.scatterChart.resize();
                    this.scatterChart.setOption({
                        grid: {
                            width: size,
                            height: size
                        }
                    });
                }
            });
        },
        disposeCharts() {
            if (this.scatterChart) {
                this.scatterChart.dispose();
                this.scatterChart = null;
            }
            if (this.barChart) {
                this.barChart.dispose();
                this.barChart = null;
            }
        },
    }
};
</script>

<style>
.dropdown-container {
    margin-bottom: 20px;
}

.dropdown-select {
    width: 200px;
    padding: 5px;
}

.charts-container {
    display: flex;
    justify-content: space-between;
    position: relative;
}

.chart-container {
    width: 48%;
    /* Each container will take approximately half of the container width */
}

.chart {
    width: 100%;
    height: 800px;
}

.legend-container {
    position: absolute;
    bottom: 0;
    /* Align to the bottom of the .chart-container */
    right: 0;
    /* Align to the right of the .chart-container */
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: flex-end;
    /* Align content to the bottom */
    justify-content: flex-start;
    padding: 0px;
    margin-left: 5%;
}

.table-container {
    margin: 20px;
    overflow-x: auto;
}

table {
    width: 100%;
    border-collapse: collapse;
}

th,
td {
    padding: 8px;
    text-align: left;
    border: 1px solid #ddd;
}

th {
    background-color: #f4f4f4;
}

.table-container {
    margin: 20px;
}

.pagination {
    display: flex;
    justify-content: center;
    margin-top: 10px;
}

.pagination button {
    margin: 0 5px;
    padding: 5px 10px;
    cursor: pointer;
}

.pagination button[disabled] {
    cursor: not-allowed;
    opacity: 0.5;
}

.pagination button.active {
    font-weight: bold;
    text-decoration: underline;
}
</style>